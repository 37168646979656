.subtotal {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 300px;
    height: 100px;
    padding: 20px;
    background-color: #f3f3f3;
    border: 1px solid #dddddd;
    border-radius: 3px;
    position: sticky;
    top: 80px;
}

.subtotal__amount {
    margin-left: 4px;
}

.subtotal__gift {
    display: flex;
    align-items: center;
    
}

.subtotal__gift > input {
    margin-right: 5px;
}

.subtotal > button {
    background: #f0c14b;
    border-radius: 2px;
    width: 100%;
    height: 30px;
    border: 1px solid;
    margin-top: 10px;
    border-color: #a88734 #9c7e31 #846a29;
    font-weight: bold;
    color: #111;
    padding: 8px;
    border-radius: 8px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    transition: all 0.25s ease;
    cursor: pointer;
    overflow: hidden;
}

.subtotal > button:hover {
    transform: translate3d(0, -5px, 0);
}

.subtotal > button:focus {
    outline: none;
}