.footer {
    color: lightgrey;
    background-color: #232f3e;
}

.back__totop {
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 13px;
    background-color: #37475a;
}

.back__totop a {
    color: lightgrey;
    font-weight: 600;
    text-decoration: none;
}

.footer__links__container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 50px ;
    padding-bottom: 48px ;
    color: lightgrey;
    background-color: #232f3e;
    
}

.footer__links__head {
    display: flex;
    justify-content: center;
    color: lightgrey;
    background-color: #232f3e;
    font-size: 16px;
    padding-top: 16px;
    padding-left: 18px;
    padding-right: 18px;
    padding-bottom: 8px;
    
}

.links__container {
    margin-left: 16px;
    margin-right: 16px;
}

.footer__links__nav {
    display: flex;
    justify-content: center;
    color: lightgrey;
    background-color: #232f3e;
    text-decoration: none;
    font-size: 14px;
    padding-top: 8px;
    
}

.footer__logo__container {
    /* border-top: thin solid lightgray #37475a; */
    border-top: thin solid #37475a;
}

.footer__logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    background-color: #232f3e;
    padding: 20px;
}