.payment {
    
    background-color: white;

}

/* Target the h1 tag in the payment__container */
.payment__container > h1 {
    text-align: center;
    padding: 10px;
    font-weight: 400;
    background-color: rgb(234, 237, 237);
    /* border-bottom: 1px solid lightgray; */
}

.payment__container > h1 a {
    text-decoration: none;
}

.payment__section {
    display: flex;
    
    background-color: white;
    padding: 30px;
    margin: 0 20px;
    border-bottom: 1px solid lightgray;
}

.payment__title {
    flex: 0.2;
}

.payment__address, .payment__items, .payment__details {
    flex: 0.8;
}

#payment__method {
    position: sticky;
    bottom: 0;
    border-top: 1px solid lightgray;
}

.payment__details > form {
    max-width: 400px;
}

.payment__details > h3 {
    padding-bottom: 20px;
}

.payment__details > form > div > button {
    background: #f0c14b;
    width: 100%;
    height: 30px;
    border: 1px solid;
    font-weight: bolder;
    margin-top: 10px;
    border-color: #a88734 #9c7e31 #846a29;
    color: #111;
    padding: 8px;
    border-radius: 8px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    transition: all 0.25s ease;
    cursor: pointer;
    overflow: hidden;
}

.payment__details > form > div > button:hover {
    transform: translate3d(0, -5px, 0);
}
